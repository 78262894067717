<template>
  <b-modal
    v-model="_state"
    size="lg"
    footer-class="border-top-0"
    centered
    no-close-on-esc
    no-close-on-backdrop
    @show="resetData"
    hide-footer
  >
    <template v-slot:modal-title>
      <h5 class="text-secondary mb-0">
        {{ $t("registration") }}
      </h5>
    </template>

    <template v-slot:default>
      <base-registration-form
        userType="maintainer"
        @submit="onSubmit"
        @cancel="onCancel"
      />
    </template>
  </b-modal>
</template>

<script>
import BaseRegistrationForm from "../Base/RegistrationForm";

export default {
  components: {
    BaseRegistrationForm
  },
  props: {
    state: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    _state: {
      get() {
        return this.state;
      },
      set(value) {
        this.setModalState(value);
      }
    }
  },
  methods: {
    async resetData() {
      await this.$store.dispatch("parameters/retrieveCategories");
    },
    onCancel() {
      this.setModalState(false);
    },
    async onSubmit(userInfo) {
      try {
        this.$store.commit("loader/SET_LOADER", {});

        await this.$store.dispatch("user/createUser", {
          userInfo
        });
        await this.$store.dispatch("userMaintainer/retrieveMaintainerUsers");

        this.setModalState(false);
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("loader/RESET");
      }
    },
    setModalState(value) {
      this.$emit("update:state", value);
    }
  }
};
</script>

<style lang="scss" scoped></style>
